import React from 'react';
import Backgroundimage from '../../assets/images/background/afrobowl.jpeg';
import { Link } from 'react-router-dom'
import logonew from '../../assets/images/af_logo.png'

function Main() {
    return (
        <>
            <footer className="main-footer">
                <div className="image-layer" style={{ backgroundImage: `url(${Backgroundimage})` }}> </div>
                <div className="upper-section">
                    <div className="auto-container">
                        <div className="row clearfix">
                            <div className="footer-col info-col col-lg-6 col-md-12 col-sm-12">
                                <div className="inner wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="content">
                                        <div className="logo"><Link to="/" title="AfroBowl -Street Food Africain"><img src={logonew} alt="" title="AfroBowl -Street Food Africain" /></Link></div>
                                        <div className="info">
                                            <ul>
                                                <li>Av. Charles-Quint 43, 1082 Ganshoren, Belgique</li>
                                                <li><Link to="tel:+32496244524">+32496244524</Link></li>
                                                <li>Ouvert 7j/7: 12.00 - 23.00</li>
                                            </ul>
                                        </div>
                                        <div className="separator"><span></span><span></span><span></span></div>
                                        <div className="info">
                                            <ul>
                                                <li>Centre commercial du DOCKS Bruxsel</li>
                                                <li>Boulevard Lambermont 1, 1000 Bruxelles, Belgium</li>
                                                <li><Link to="tel:+32472885217">+32472885217</Link></li>
                                                <li>Ouvert 7j/7: 12.00 - 22.00</li>
                                            </ul>
                                        </div>
                                        <div className="separator"><span></span><span></span><span></span></div>
                                        <div className="info">
                                            <ul>
                                                <li><Link to="mailto:info@afrobowl.be">Vos demandes : info@afrobowl.be</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="footer-col links-col col-lg-3 col-md-6 col-sm-12">
                                <div className="inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <ul className="links">
                                        <li> <Link to="https://www.instagram.com/afrobowl.belgium/">instagram</Link></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="footer-col links-col last col-lg-3 col-md-6 col-sm-12">
                                <div className="inner wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <ul className="links">
                                        <li> <Link to="tiktok.com/@afrobowl">Tiktok</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="auto-container">
                        <div className="copyright">&copy; 2023 AfroBowl. All Rights Reserved   |    Crafted by <Link to="https://justonline.be" target="blank">JustOnline</Link></div>
                    </div>
                </div>

            </footer>
            <div className="scroll-to-top scroll-to-target" data-target="html"><span className="icon fa fa-angle-up"></span></div>
        </>
    )
}

export default Main