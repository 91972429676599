import React from 'react'
import { Link } from 'react-router-dom'
import Backgroundoffer from '../../assets/images/resource/af_discover.jpg';
import recordbg1 from '../../assets/images/resource/af_discover.jpg'
import recordbg2 from '../../assets/images/background/af_bg-4_0.png'
import recordbg3 from '../../assets/images/resource/badge-2.png'

function Specialdish({file}) {
    return (
        <>
            <section id="discover" className="special-dish">
                <div className="outer-container">
                    <div className="row clearfix">
                        <div className="content-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <div className="right-bg"><img src={recordbg2} alt="" title="" /></div>
                            <div className="inner wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">

                                <div className="title-box">
                                    <span className="badge-icon"><img src={recordbg3} alt="" title="" /></span>
                                    <div className="subtitle"><span>Un voyage à ne pas manquer</span></div>

                                    <div className="pattern-image">
                                        <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                                    </div>

                                    <h2>Nos plateaux découvertes</h2>
                                    <div className="text">Au cœur d'Afro Bowl, l'authenticité culinaire de l'Afrique s'exprime avec éclat à travers nos plateaux découvertes soigneusement élaborés. Nous avons imaginé ces somptueuses compositions gastronomiques pour vous offrir une véritable aventure gustative, réunissant harmonieusement plusieurs saveurs uniques en un seul plat.</div>
                                    <div className="text">Dès que vous posez les yeux sur nos plateaux, vous êtes transportés dans un voyage culinaire extraordinaire à travers les contrées fascinantes de l'Afrique. Chaque élément de ces assortiments est sélectionné avec soin, représentant un pan de la diversité culturelle et culinaire de ce continent envoûtant.</div>
                                </div>

                                <div className="link-box">
                                    {file
                                        ? 
                                            <Link to={file} target="blank" className="theme-btn btn-style-two clearfix">
                                                <span className="btn-wrap">
                                                    <span className="text-one">Voir le menu</span>
                                                    <span className="text-two">Découvrir !</span>
                                                </span>
                                            </Link>
                                        : 
                                            <Link to="/commander" className="theme-btn btn-style-two clearfix">
                                                <span className="btn-wrap">
                                                    <span className="text-one">Commander</span>
                                                    <span className="text-two">Bon app !</span>
                                                </span>
                                            </Link>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="image-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="image-layer" style={{ backgroundImage: `url(${Backgroundoffer})` }}></div>
                                <div className="image"><img src={recordbg1} alt="" /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Specialdish