import React from 'react'
import { Link } from 'react-router-dom'
import bg5 from '../../assets/images/background/af_bg_delivery.png'
import bg6 from '../../assets/images/background/af_maps_bg.png'
import ny from '../../assets/images/resource/af_koekelberg.jpg'
import docks from '../../assets/images/resource/af_docks.jpg'

function Book() {
    return (
        <>
            <section className="online-reservation inner-page">
                <div className="left-bg"><img src={bg5} alt="Created by Bleu Tuong from Noun Project" title="Delivery scooter" /></div>
                <div className="right-bg"><img src={bg6} alt="Created by Webtechops LLP from Noun Project" title="Maps" /></div>
                <div className="auto-container">
                    <div className="default-form reservation-form">
                        <div className="lower-link-box text-center">
                            
                            <Link to="https://www.takeaway.com/be-en/menu/afro-bowl" target="blank" className="theme-btn btn-style-one clearfix" style={{ margin: '25px' }}>
                                <span className="btn-wrap" >
                                    <span className="text-one">Sur Takeaway</span>
                                    <span className="text-two">Voir le menu</span>
                                </span>
                            </Link>

                            <Link to="https://deliveroo.be/fr/menu/brussels/koekelberg-est/afro-bowl" target="blank" className="theme-btn btn-style-one clearfix" style={{ margin: '25px' }}>
                                <span className="btn-wrap" >
                                    <span className="text-one">Sur Deliveroo</span>
                                    <span className="text-two">Voir le menu</span>
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="map-location-section">
                    <div className="auto-container">
                        <div className="outer-box">
                            <div className="row clearfix">
                                <div className="reserv-col col-lg-8 col-md-12 col-sm-12">
                                    <div className="inner">
                                        <iframe src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Afro%20Bowl,%20Av.%20Charles-Quint%2043,%201082%20Ganshoren,%20Belgique+(Afro%20Bowl)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" width="100%" height="600" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                </div>
                                <div className="info-col col-lg-4 col-md-12 col-sm-12">
                                    <div className="inner">
                                        <div className="title">
                                            <h2>Bruxelles</h2>
                                        </div>
                                        <div className="data">
                                            <ul className="info">
                                                <li>Afro Bowl, Av. Charles-Quint 43<br/>1082 Ganshoren, Belgique</li>
                                                <div className="separator"><span></span></div>
                                                <li>Phone : +32496244524 </li>
                                                <div className="separator"><span></span></div>
                                                <li> Email : info@afrobowl.be </li>
                                                <div className="separator"><span></span></div>
                                                <li><strong>Ouvert 7j/7</strong><br />12:00 - 23:00</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="outer-box">
                            <div className="row clearfix">
                                <div className="reserv-col col-lg-8 col-md-12 col-sm-12">
                                    <div className="inner">
                                        <iframe src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Docks%20Boulevard%20Lambermont%201,%201000%20Bruxelles,%20Belgium+(Afro%20Bowl%20Docks)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" width="100%" height="600" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                </div>
                                <div className="info-col col-lg-4 col-md-12 col-sm-12">
                                    <div className="inner">
                                        <div className="title">
                                            <h2>DOCKS Bruxsel</h2>
                                        </div>
                                        <div className="data">
                                            <ul className="info">
                                                <li>Boulevard Lambermont 1<br/>1000 Bruxelles, Belgium</li>
                                                <div className="separator"><span></span></div> 
                                                <li>Phone : +32472885217</li>
                                                <div className="separator"><span></span></div> 
                                                <li>Email : info@afrobowl.be </li>
                                                <div className="separator"><span></span></div>
                                                <li><strong>Ouvert 7j/7</strong><br />12:00 - 22:00</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="location-center">
                    <div className="auto-container">
                        <div className="row clearfix">

                            <div className="location-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
                                <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                                    <div className="icon-box"><img src={ny} alt="" /></div>
                                    <h4>Koekelberg</h4>
                                    <div className="text">Av. Charles-Quint 43<br />1082 Ganshoren, Belgique</div>
                                    <div className="more-link"><Link to="https://maps.google.com/maps/dir//Afro+Bowl+Av.+Charles-Quint+43+1082+Ganshoren/@50.867856,4.3134558,15z/data=!4m5!4m4!1m0!1m2!1m1!1s0x47c3c3c5b2e644bf:0x7342c46f6ed04913">En route</Link></div>
                                </div>
                            </div>
                            <div className="location-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
                                <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                                    <div className="icon-box"><img src={docks} alt="" /></div>
                                    <h4>Docks</h4>
                                    <div className="text">Boulevard Lambermont 1<br />1000 Bruxelles, Belgium</div>
                                    <div className="more-link"><Link to="https://www.google.be/maps/place/Docks+Bruxsel/@50.87947,4.3638898,16z/data=!4m10!1m2!2m1!1sdocks!3m6!1s0x47c3c3153d5ec577:0xf83848948edb3c94!8m2!3d50.87947!4d4.372902!15sCgVkb2Nrc1oHIgVkb2Nrc5IBD3Nob3BwaW5nX2NlbnRlcuABAA!16s%2Fg%2F11b76gb7cy?hl=fr&entry=ttu">En route</Link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Book