import React from 'react'
import { Link } from 'react-router-dom'
import bg3 from '../../assets/images/background/af_vegetables.png'
import img1 from '../../assets/images/resource/af_story_1.jpg'
import img2 from '../../assets/images/resource/af_story_2.jpg'

function Story() {
    return (
        <>
            <section id="story" className="story-section">
                <div className="left-bg">
                    <img src={bg3} alt="" title="" />
                </div>
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="text-col col-xl-5 col-lg-5 col-md-12 col-sm-12">
                            <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="title-box centered">
                                    <div className="subtitle">
                                        <span> Histoire </span>
                                    </div>
                                    <div className="pattern-image">
                                        <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                                    </div>
                                    <h2>Le "Street Food" premium</h2>
                                    <div className="text">Afro Bowl est le "Street Food" premium Africain, où les délices culinaires de ce continent sont à l'honneur. Notre concept innovant propose des plats traditionnels servis dans des bols pratiques, pour déguster sur place ou à emporter, vous faisant voyager au cœur des saveurs vibrantes de l'Afrique.</div>
                                    <div className="text">Chaque bol est préparé avec soin par notre équipe talentueuse de chefs passionnés, mettant en valeur des ingrédients frais et de qualité, issus de recettes ancestrales.</div>
                                </div>
                                <div className="booking-info">
                                    <div className="link-box">
                                        <Link to="/commander" className="theme-btn btn-style-two clearfix">
                                            <span className="btn-wrap">
                                                <span className="text-one">Voir un apercu</span>
                                                <span className="text-two">Entrez donc !</span>
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="image-col col-xl-7 col-lg-7 col-md-12 col-sm-12">
                            <div className="inner wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="image"><img src={img1} alt="" /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Story