import React from 'react'
import Bredcom from '../Bredcrumb/Main'
import Book from '../../components/Menhome/Book'
import Img from '../../assets/images/background/af_delivery.jpg'

function Main() {
  return (
    <>
      <Bredcom subtitle="Choisissez votre plateforme" title="Commande en ligne" Img={Img}/>  
      <Book />
    </>
  )
}

export default Main