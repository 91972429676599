import React, {memo} from 'react';
import { Link } from 'react-router-dom'

function BBanner({file}) {

    return (
        <>
            <div className="link">
                <Link to="/commander" className="theme-btn btn-style-one clearfix" style={{ margin: '25px' }}>
                    <span className="btn-wrap">
                        <span className="text-one">Commander</span>
                        <span className="text-two">Bon app !</span>
                    </span>
                </Link>
            </div>
            { file &&
            <div className="link">
                <Link to={file} target="blank" className="theme-btn btn-style-one clearfix" style={{ margin: '25px' }}>
                    <span className="btn-wrap">
                        <span className="text-one">Notre menu</span>
                        <span className="text-two">Voyage</span>
                    </span>
                </Link>
            </div> }   
        </>
    )
}

export default memo(BBanner)