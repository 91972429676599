import React, {memo} from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import Slideone from '../../assets/images/main-slider/af_slider_1.jpg'
import Slidetwo from '../../assets/images/main-slider/af_slider_2.jpg'
import BBanner from  '../Button/Banner/BBanner'
import bookicon1 from '../../assets/images/resource/af_book-icon-1.png'

function Banner({file}) {
    return (
        <Swiper className="banner-section" loop={true} spaceBetween={50} slidesPerView={1} navigation={{ nextEl: '.swiper-button-prev', prevEl: '.swiper-button-next' }}>
            <div className="banner-container">
                <div className="banner-slider">
                    <div className="swiper-wrapper">
                        <SwiperSlide>
                                <div className="swiper-slide slide-item">

                                    <div className="image-layer" style={{ backgroundImage: `url(${Slideone})` }}></div>

                                    <div className="auto-container">
                                        <div className="content-box">
                                            <div className="content">
                                                <div className="clearfix">
                                                    <div className="inner">
                                                        <div className="links-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                                            {file
                                                                ? <BBanner file={file}/>
                                                                : <BBanner/>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                        </SwiperSlide>

                            <SwiperSlide>
                                <div className="swiper-slide slide-item">
                                    <div className="image-layer" style={{ backgroundImage: `url(${Slidetwo})` }}></div>
                                    <div className="auto-container">
                                        <div className="content-box">
                                            <div className="content">
                                                <div className="clearfix">
                                                    <div className="inner">
                                                        <div className="links-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                                            {file
                                                                ? <BBanner file={file}/>
                                                                : <BBanner/>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                    </div>
                </div>
            </div>

            <div className="book-btn">
                <Link to="/commander" className="theme-btn">
                    <span className="icon">
                        <img src={bookicon1} alt="" title="" />
                    </span>
                </Link>
                
            </div>
            <div className="swiper-button-prev"><span className="fal fa-angle-left"></span></div>
            <div className="swiper-button-next"><span className="fal fa-angle-right"></span></div>
                
        </Swiper>
    )
}

export default memo(Banner)
