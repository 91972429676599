import React, { useState, useEffect} from 'react'
import Special from '../Menhome/Special'
import Testimonials from '../Menhome/Contact'
import Banner from '../Menhome/Banner'
import Story from '../Menhome/Story'
import Specialdish from '../Menhome/Specialdish'
import { Link } from 'react-router-dom'
import sidebarlogo from '../../assets/images/af_logo.png'


function Main() {
    const CONTENTFULL_SPACE_ID = process.env.REACT_APP_CONTENTFULL_SPACE_ID;
    const CONTENTFULL_ACCESS_TOKEN = process.env.REACT_APP_CONTENTFULL_ACCESS_TOKEN;
    const query = `
            {afrobowlFilesCollection(skip:0, limit:20)
             {
                items
              {
                nameFile
                urlFile
              }
             }
            } `
    const [files, setFiles] = useState(null);
    useEffect(() => {
        if (!files){
        window
          .fetch(`https://graphql.contentful.com/content/v1/spaces/`+CONTENTFULL_SPACE_ID+`/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // Authenticate the request
              Authorization: "Bearer "+CONTENTFULL_ACCESS_TOKEN,
            },
            // send the GraphQL query
            body: JSON.stringify({ query }),
          })
          .then((response) => response.json())
          .then(({ data, errors }) => {
            if (errors) {
              console.error(errors);
            }
            // rerender the entire component with new data
            if (data){
                setFiles(data.afrobowlFilesCollection.items[0]);
                console.log(data)
            } else {
                console.log("Files: no data from Contentfull")
            }
            
          });
      }}, []);

      let hero
      let special
      let specialdish
      if (files){
        hero=<Banner file={files.urlFile} />
        special = <Special file={files.urlFile}/>
        specialdish=<Specialdish file={files.urlFile}/>
      } else {
        hero=<Banner/>
        special = <Special />
        specialdish=<Specialdish />
      }

    return (
        <>
            <div className="menu-backdrop"> </div>

            <section className="hidden-bar">
                <div className="inner-box">
                    <div className="cross-icon hidden-bar-closer"><span className="far fa-close"></span></div>
                    <div className="logo-box"><Link to="/" title="Afro Bowl - Fresh, fast & tasty"><img src={sidebarlogo} alt="Afro Bowl logo" title="Afro Bowl - Fresh, fast & tasty" /></Link></div>
                    <div className="side-menu">
                        <ul className="navigation clearfix">
                            <li className="current dropdown"><Link to="#">Home 0</Link>
                                <ul>
                                    <li><Link to="/">Home 1 Left Header</Link></li>
                                    <li><Link to="/hometwo">Home 1 Center Header</Link></li>
                                    <li><Link to="/">Home 3 Video Hero</Link></li>
                                </ul>
                            </li>
                            <li className="dropdown"><Link to="/menu">Menus</Link>
                                <ul>
                                    <li><Link to="/menuone">Menu List 1</Link></li>
                                    <li><Link to="/menutwo">Menu List 2</Link></li>
                                    <li><Link to="/menuthree">Menu List 3</Link></li>
                                    <li><Link to="/menufour">Menu List 4</Link></li>
                                </ul>
                            </li>
                            <li><Link to="/about">About Us</Link></li>
                            <li><Link to="/ourchefs">Our chefs</Link></li>
                            <li className="dropdown"><Link to="#">Pages</Link>
                                <ul>
                                    <li><Link to="#">Dropdown Menu 1</Link></li>
                                    <li><Link to="#">Dropdown Menu 2</Link></li>
                                    <li><Link to="#">Dropdown Menu 3</Link></li>
                                    <li className="dropdown"><Link to="#">Dropdown Menu 4</Link>
                                        <ul>
                                            <li><Link to="#">Dropdown Menu level 2</Link></li>
                                            <li><Link to="#">Dropdown Menu level 2</Link></li>
                                            <li><Link to="#">Dropdown Menu Level 3</Link></li>
                                        </ul>
                                    </li>
                                    <li><Link to="#">Dropdown Lorem 5</Link></li>
                                </ul>
                            </li>
                            <li><Link to="/contact">Contact</Link></li>
                        </ul>
                    </div>

                    <h2>Visit Us</h2>
                    <ul className="info">
                        <li>Restaurant St, Delicious City, <br /> London 9578, UK</li>
                        <li>Open: 9.30 am - 2.30pm</li>
                        <li><Link to="mailto:booking@domainame.com">booking@domainame.com</Link></li>
                    </ul>
                    <div className="separator"><span></span></div>
                    <div className="booking-info">
                        <div className="bk-title">Booking request</div>
                        <div className="bk-no"><Link to="tel:+88-123-123456">+88-123-123456</Link></div>
                    </div>
                </div>
            </section>

            {hero}
            {special}
            <Story />
            {specialdish}
            <Testimonials />
        </>
    )
}

export default Main