import React, { useRef, useState, useEffect} from 'react'
import Bgtwo from '../../assets/images/background/af_testi.jpg'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Controller } from 'swiper';

SwiperCore.use([Controller]);
function Contact() {
    const CONTENTFULL_SPACE_ID = process.env.REACT_APP_CONTENTFULL_SPACE_ID;
    const CONTENTFULL_ACCESS_TOKEN = process.env.REACT_APP_CONTENTFULL_ACCESS_TOKEN;
    const query = `
            {afrobowlGoogleReviewsCollection(skip:0, limit:20)
             {
                items
              {
                authorName 
                authorUrl
                profilePhotoUrl
                rating
                time
                originalLanguage
                text
              }
             }
            } `
    const [reviews, setReviews] = useState(null);
    useEffect(() => {
        window
          .fetch(`https://graphql.contentful.com/content/v1/spaces/`+CONTENTFULL_SPACE_ID+`/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // Authenticate the request
              Authorization: "Bearer "+CONTENTFULL_ACCESS_TOKEN,
            },
            // send the GraphQL query
            body: JSON.stringify({ query }),
          })
          .then((response) => response.json())
          .then(({ data, errors }) => {
            if (errors) {
              console.error(errors);
            }
            // rerender the entire component with new data
            if (data){
                setReviews(data.afrobowlGoogleReviewsCollection.items);
                console.log(data)
            } else {
                console.log("no data from Contentfull")
            }
            
          });
      }, []);

    const swiper1 = useRef(null);
    const swiper2 = useRef(null);

    const [flag, setflag] = useState(false)

    const slider2 = useRef()
    const duration = 500;
    const syncPosition = (e) => {
        if (!flag) {
            setflag(false)
            if (slider2.current) {
                slider2.current.to(e.item.index, duration)
            }
            setflag(false)
        }
    }

    if (!reviews) {
        return (
            <>
                <section id="testimonials" className="">
                    <div className="testimonials-section" >
                        <div className="image-layer" style={{ backgroundImage: `url(${Bgtwo})` }} alt='Gold star ornaments on beige background by Thamrongpat Theerathammakorn from <a href="https://thenounproject.com/photo/gold-star-ornaments-on-beige-background-5naYl0/" target="_blank" title="Gold star ornaments on beige background Photo">Noun Project</a> (CC BY-NC-ND 2.0)'></div>
                        <div className="auto-container">
                            <span>Loading reviews...</span>
                        </div>
                    </div>
                </section>
            </>
        )
      }

    return (
        <>
            <section id="testimonials" className="">
                <div className="testimonials-section" >
                    <div className="image-layer" style={{ backgroundImage: `url(${Bgtwo})` }} alt='Gold star ornaments on beige background by Thamrongpat Theerathammakorn from <a href="https://thenounproject.com/photo/gold-star-ornaments-on-beige-background-5naYl0/" target="_blank" title="Gold star ornaments on beige background Photo">Noun Project</a> (CC BY-NC-ND 2.0)'></div>
                    <div className="title-box centered">
                        <div className="subtitle">
                            <span>Les avis google de nos clients</span>
                        </div>
                        <div className="pattern-image">
                            <img src={require('../../assets/images/icons/separator.svg').default} alt="mySvgImage" />
                        </div>
                        <h2>Vos témoignages</h2>
                        <br/>
                        <br/>
                    </div>
                    <div className="auto-container">
                        <div className="carousel-box owl-theme">
                            <Swiper className="testi-top"
                                ref={swiper1}
                                controller={{ control: swiper2.current }}
                                items={1} loop margin={10} autoplay onChange={(e) => syncPosition(e)}
                                navigation={{ nextEl: '.swiper-button-prev', prevEl: '.swiper-button-next' }}>
                                <div className="swiper-button-prev"><span className="fal fa-angle-left"></span></div>
                                <div className="swiper-button-next"><span className="fal fa-angle-right"></span></div>
                                {reviews.map((review, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="slide-content">
                                            <div className="quotes">”</div>
                                            
                                            <div className="text quote-text">{review.text}</div>
                                            <br/>
                                            <div className="text quote-text">{review.rating} ⭐</div>
                                            <br/>
                                            <br/>
                                            <a target="_blank" href={review.authorUrl} rel="noopener noreferrer" className="">
                                            <div className="image">
                                                    <img src={review.profilePhotoUrl} alt="profile photo url from google" /> 
                                            </div>
                                            <br/>
                                            <div className="auth-title">
                                                    {review.authorName}
                                            </div>
                                            </a>
                                        </div>
                                    </SwiperSlide>))
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact