import React from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import menuimgone from '../../assets/images/resource/af_menu-image-11.jpg';
import menuimgtwo from '../../assets/images/resource/af_menu-image-12.jpg';
import menuimgthree from '../../assets/images/resource/af_menu-image-13.jpg';
import menuimgfour from '../../assets/images/resource/af_menu-image-14.jpg';

function Special({file}) {
  const options = {
    slidesPerView: 4,
    spaceBetween: 45,
    loop: true,
    navigation: {
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next',
    },
    autoplay: {
      delay: 7000,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 15,
      },
      600: {
        slidesPerView: 1,
        spaceBetween: 15,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 4,
      },
    },
  };

  return (
    <>
      <section id="special" className="special-offer">
        <br/>
        <br/>
        <div className="outer-container">
          <div className="auto-container">
            <div className="title-box centered">
              <div className="subtitle">
                <span>Le "Street Food" premium Africain</span>
              </div>
              <div className="pattern-image">
                <img src={require('../../assets/images/icons/separator.svg').default} alt="mySvgImage" />
              </div>
              <h2>Actuellement chez Afro Bowl</h2>

            </div>
            <Swiper className="dish-gallery-slider " {...options}>                
              <SwiperSlide className="offer-block-two ">
                <div className="inner-box">
                  <div className="image">
                      <img src={menuimgtwo} alt="" />
                  </div>
                  <h4>
                    <Link to="/commander">Afro'Burger plantain-boeuf</Link>
                  </h4>
                  <div className="text desc">Burger double steak de boeuf garni des célèbres "alocos" accompagné par la sauce du chef</div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="offer-block-two margin-top">
                <div className="inner-box">
                  <div className="image">
                      <img src={menuimgone} alt="" />
                  </div>
                  <h4 >
                    <Link to="/commander">Afro'Tacos poulet-boeuf</Link>
                  </h4>
                  <div className="text desc">Tacos inspiré des saveurs africaines</div>
                </div>
              </SwiperSlide>

              

              <SwiperSlide className="offer-block-two">
                <div className="inner-box">
                  <div className="image">
                      <img src={menuimgthree} alt="" />
                  </div>
                  <h4>
                    <Link to="/commander">Poulet Muamba</Link>
                  </h4>
                  <div className="text desc">Le poulet Muamba est un plat phare du Congo fait à base de beurre d'arachide et de sauce tomate épicée</div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="offer-block-two margin-top">
                <div className="inner-box">
                  <div className="image">
                      <img src={menuimgfour} alt="" />
                  </div>
                  <h4>
                    <Link to="/commander">Plateau découverte</Link>
                  </h4>
                  <div className="text desc">Les plateaux découvertes ont été pensé pour réunir plusieurs saveurs d'Afrique</div>
                </div>
              </SwiperSlide>
            </Swiper>
            <div className="swiper-button-prev"><span className="fal fa-angle-left"></span></div>
            <div className="swiper-button-next"><span className="fal fa-angle-right"></span></div>

            <div className="lower-link-box text-center">
              {file
              ? 
                <Link to={file} target="blank" className="theme-btn btn-style-two clearfix">
                  <span className="btn-wrap">
                    <span className="text-one">Voir le menu</span>
                    <span className="text-two">Bon appétit</span>
                  </span>
                </Link>
              : 
                <Link to="/commander" className="theme-btn btn-style-two clearfix">
                  <span className="btn-wrap">
                    <span className="text-one">Commander</span>
                    <span className="text-two">Bon app !</span>
                  </span>
                </Link>
              }
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Special;
